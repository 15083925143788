import React, { useEffect, useState, useRef } from "react";
import Header from "../Wrapper/Header";
import Loader from "../Component/Home/Loader";
import Button from "react-bootstrap/Button";
import FooterComponent from "../Component/Home/FooterComponent";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const Contact = (props) => {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r0ed87o",
        "template_07eyz4l",
        form.current,
        "22QMggfsoYJvp2Lah"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`SOHO Fertilizer | ${props.strings["iletisim"]}`}</title>
        <meta
          property="title"
          content={`SOHO Fertilizer | ${props.strings["iletisim"]}`}
        />

        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`SOHO Fertilizer | ${props.strings["iletisim"]}`}
        />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/contact"}
        />

        <link rel="canonical" href={"https://www.sohofertilizer.com/contact"} />
      </Helmet>
      <ToastContainer />
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <Header />
      <div>
        <div className="">
          <div className="backImg3 d-flex justify-content-start">
            <div className="title d-flex align-items-center ">
              <span className="main-title p-3">
                {props.strings["iletisim_tek"]}
              </span>

              <span className="slash-icon white">/ </span>

              <img
                src="/assets/images/telephone.png"
                className="banner_icon p-3"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </div>
          </div>

          <div className="grid-row container-fluid d-flex justify-content-center">
            <div id="content" role="main">
              <div className="row container-fluid mt-5">
                <div className="col-lg-4 col-md-6">
                  <div className="row">
                    <div className="grid-contact mt-4">
                      <i className="fa  fa-globe" />
                      <div className="contact-content">
                        <p>
                          <strong>{props.strings["londra"]}:</strong>
                          <br />
                          106 Church Road, Richmond Surrey TW10 6LW
                          <br />
                          United Kingdom
                        </p>
                        <p>
                          <a href="tel:447517337220">+44 (7517) 337220</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="grid-contact mt-4">
                      <i className="fa  fa-home" />
                      <div className="contact-content">
                        <p>
                          <strong>{props.strings["ankara"]}</strong>
                          <br />
                          Dağyaka Mah. 2022 Cad. No:14
                          <br />
                          Kahramankazan / ANKARA
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row " style={{ paddingBottom: "10px" }}>
                    <div className="grid-contact">
                      <i className="fa fa-phone" />
                      <div className="contact-content">
                        <p>
                          <strong>{props.strings["tel"]}</strong>
                          <br />
                          <a href="tel:905322418171">+90 (534) 339 03 06</a>
                        </p>
                        <p>
                          <strong>{props.strings["inter"]}</strong>
                          <br />
                          <a href="tel:905300816634">+90 (530) 081 66 34</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="grid-contact">
                      <i className="fa fa-envelope-o" />
                      <div className="contact-content">
                        <p>
                          <strong>E-mail:</strong>
                          <br />
                          <a href="mailto:info@demirorganik.com">
                            info@demirorganik.com
                          </a>{" "}
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="grid-contact">
                      <i className="fa fa-share-alt" />

                      <div className="contact-content">
                        <strong>{props.strings["takip_edin"]}:</strong>
                        <br />
                        <br />

                        <a
                          href="https://www.instagram.com/demirorganik/"
                          className="contact-round2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-instagram d-flex justify-content-center icon-style" />
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCJpY5g1TIOjNtz2YPWCZiHQ"
                          className="contact-round2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-youtube d-flex justify-content-center icon-style" />
                        </a>
                        <a
                          href="https://www.facebook.com/demirorganiktarim/"
                          className="contact-round2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-facebook d-flex justify-content-center icon-style" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/demir-organik-tar%C4%B1m-ltd-%C5%9Fti/"
                          className="contact-round2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-linkedin d-flex justify-content-center icon-style" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 mt-5 ">
                  <div className="composer">
                    <div
                      className="info-boxes error-message"
                      id="feedback-form-errors"
                    >
                      <div className="info-box-icon">
                        <i className="fa fa-times" />
                      </div>
                      <strong>Error box</strong>
                      <br />
                      <div className="message" />
                    </div>
                    <div
                      className="info-boxes confirmation-message"
                      id="feedback-form-success"
                    >
                      <div className="info-box-icon">
                        <i className="fa fa-check" />
                      </div>
                      <strong>Confirmation box</strong>
                      <br />
                      Vestibulum sodales pellentesque nibh quis imperdiet
                      <div className="close-button">
                        <i className="fa fa-times" />
                      </div>
                    </div>
                  </div>
                  <div className="email_server_responce" />
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    id="feedback-form"
                    className="message-form clear"
                  >
                    <div className="row">
                      <p className="col-md-6 message-form-author">
                        <input
                          id="author"
                          name="name"
                          type="text"
                          required
                          placeholder={props.strings["ad-soyad"]}
                          size={30}
                          aria-required="true"
                        />
                      </p>
                      <p className="col-md-6 message-form-email">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          required
                          placeholder={props.strings["e-mail"]}
                          size={30}
                          aria-required="true"
                        />
                      </p>
                    </div>
                    <div className="row">
                      <p className="col-md-6 message-form-phone">
                        <input
                          id="phone"
                          name="phone"
                          required
                          type="tel"
                          placeholder={props.strings["telefon"]}
                          size={20}
                          aria-required="true"
                        />
                      </p>
                      <p className="col-md-6 message-form-company">
                        <input
                          id="company"
                          name="company"
                          required
                          type="text"
                          placeholder={props.strings["sirket"]}
                          size={30}
                          aria-required="true"
                        />
                      </p>
                    </div>
                    <div className="row">
                      <p className="col-md-12  message-form-message">
                        <textarea
                          className="contactTextArea"
                          id="message"
                          name="message"
                          cols={20}
                          rows={10}
                          placeholder={props.strings["mesaj"]}
                          aria-required="true"
                          defaultValue={""}
                        />
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-1 ">
                        <Button
                          className="form-submit rectangle-button green medium buttonLeft"
                          name="submit"
                          type="submit"
                          id="submit"
                          defaultValue="Send"
                        >
                          {props.strings["gonder"]}
                        </Button>
                        <h1
                          style={{
                            color: "white",
                            margin: "0px",
                            padding: "0px",
                            fontSize: "1px",
                          }}
                        >
                          {props.strings["iletisim"]}
                        </h1>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-3 col-md-12 d-flex justify-content-center align-items-center ">
                  <img
                    src="/assets/images/cicek.png"
                    alt="soho fertilizer damlama gübreleri demir organik"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" contact-map">
        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6113.850211484385!2d32.746113!3d39.987779!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3498c0307c9ab%3A0xac77d4b45f1ad8d5!2sSerhat%2C%201417.%20Sk.%20No%3A48%2C%2006378%20%C4%B0vedik%20Osb%2FYenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1664974441325!5m2!1str!2str"
            height={450}
            style={{ border: 0, width: "100vw" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      <FooterComponent />
    </>
  );
};
Contact.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Contact));
